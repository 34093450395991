
<template>
    <div>
        <div class="filter-panel">
            <input
                    type="text"
                    placeholder="搜索房间号"
                    class="cs-input"
                    style="border-radius: 4px"
                    v-model="queryRooms.roomNo"
            />
            <CSSelect style="margin-right: 20px">
                <select v-model="queryRooms.state">
                    <option value="">房间状态不限</option>
                    <option :value="1">启用</option>
                    <option :value="2">禁用</option>
                    <!--          <option :value="3">作废</option>-->
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 20px">
                <select v-model="queryRooms.buildingId" @change="changeBuilding">
                    <option value="">全部楼栋</option>
                    <option
                            v-for="building in buildings"
                            :key="building.code"
                            :value="building.code"
                    >
                        {{ building.name }}
                    </option>
                </select>
            </CSSelect>
            <CSSelect style="margin-right: 30px">
                <select v-model="queryRooms.floor">
                    <option value="">楼层不限</option>
                    <option v-for="floor in floorSpace" :value="floor" :key="floor">
                        {{ floor }}
                    </option>
                </select>
            </CSSelect>
            <button
                    type="button"
                    class="btn btn-primary btn-sm"
                    v-on:click="queryRoom()"
            >
                查询
            </button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template
                        v-slot:header
                        v-if="
            DEPARTMENT_TYPES.OPERATIONS === +accountInfo.dutyType ||
            DEPARTMENT_TYPES.DIRECTOR === +accountInfo.dutyType
          "
                >
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>添加时间</th>
                        <th>房间号</th>
                        <th>房间状态</th>
                        <th>所在楼栋楼层</th>
                        <th>分割房间</th>
                        <th>建筑面积<span>(㎡)</span></th>
                        <th>水表/电表</th>
                        <th>水电公摊承担方	</th>
                        <th>业主</th>
                        <th>租客</th>
                        <th>创建人</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="room in roomList" :key="room.roomId">
                        <td class="date-td">{{ room.createTime || "-" }}</td>
                        <td>
                            {{ room.roomNo || "-" }}
                        </td>
                        <td>{{ roomState[room.state] || "-" }}</td>
                        <td>
                            {{ room.buildingName || "-" }}- {{ room.floor || "-" }}
                        </td>
                        <td>
              <span v-if="room.divisionNum > 0&&room.isDivision != 1"
                    class="btn-active"
                    @click="getDivision(room)">
                {{ room.divisionNum }}个房间
              </span>
                            <span v-else>
                                 -
                            </span>

                        </td>
                        <td>
                            {{ room.space || "-" }}
                        </td>
                        <td>
              <span
                      v-if="room.waterCount || room.electricityCount"
                      class="btn-active"
                      @click="getWater(room.waterMeter,room.electricityMeter)"
              >{{(room.waterCount || 0) + (room.electricityCount || 0) }}个</span
              >
                            <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="room.waterAndElectricity === 0">
                            {{room.company || '-'}}
                          </span>
                            <span v-else-if="room.waterAndElectricity === 1">
                            暂不确定
                          </span>
                            <span v-else-if="room.waterAndElectricity === 2">
                            {{ room.hydropowerCompanyName }}
                          </span>
                            <span v-else>
                            -
                          </span>
                        </td>
                        <td>{{ room.ownerName ? room.ownerName : "-" }}</td>
                        <td>
                            <template v-if="room.lesseeCompany">
                                <span class="allow-click" @click="lookTenant(room)">查看</span>
                            </template>
                            <template v-else-if="room.relevanceLesseeName">
                                <span class="allow-click" @click="lookTenant(room)">查看</span>
                            </template>
                            <template v-else>-</template>
                        </td>
                        <!--                            <td>
                                                      {{ getBinderName(room.ownerRef) }}
                                      </td>-->

                        <td>{{ room.createUser || "-" }}</td>
                    </tr>
                </template>
            </CSTable>
            <Pagination name="pagination" component="pagination"></Pagination>
        </div>
        <GetHydropower
                name="getHydropower"
                componentName="GetHydropower"
        ></GetHydropower>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import GetHydropower from "@/components/GetHydropower";
    import CSSelect from "@/components/common/CSSelect";
    import {
        queryRoomRentUrl,
        queryBuildingUrl,
        queryRoomRentUrls,
    } from "@/requestUrl";
    import { DEPARTMENT_TYPES } from "@/constant";
    import CSTable from "@/components/common/CSTable";

    export default {
        name: "roomList",
        props: {},
        created() {
            window.addEventListener("keydown", this._queryRoomMethodDown);
        },
        beforeDestroy() {
            window.addEventListener("keydown", this._queryRoomMethodDown);
        },
        components: {
            CSTable,
            Pagination,
            CSSelect,
            GetHydropower
        },
        data() {
            return {
                roomState: {
                    1: "启用",
                    2: "禁用",
                    3: "作废",
                },
                filterHeight: 0,
                queryRooms: {
                    buildingId: "",
                    roomNo: "",
                    state: "",
                    floor: "",
                },
                DEPARTMENT_TYPES,
                accountInfo: this.$vc.getCurrentStaffInfo(),
                roomUnits: [],
                buildings: [],
                listRoomData: [],
                roomList: [],
                floorSpace: [],
            };
        },
        mounted() {
            this.queryRoom();
            //根据 参数查询相应数据
            this.getBuildings();
            this.$vc.on("roomList", "listRoom", (_param) => {
                this.queryRoom();
            });
            this.$vc.on("roomList", "loadData", (_param) => {
                this.queryRoom();
            });
            this.$vc.on(
                this.$route.path,
                "pagination",
                "page_event",
                (_currentPage) => {
                    this.queryRoom(_currentPage);
                }
            );
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            window.addEventListener("resize", () => {
                this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
            });
        },
        updated() {
            this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
        },
        methods: {
            /**
             * 查看租客
             * @param {Object} roomInfo 租客信息
             * */
            lookTenant(roomInfo) {
                this.$CSDialog.alert({
                    width: "558px",
                    title: "租客",
                    messageHtml: `
                    <div class="comment-panel" style="--item-label: 160px;">
                        <div class="item right">
                            <div class="item-label">企业/个人名称</div>
                            <div>${roomInfo.lesseeCompany || roomInfo.relevanceLesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">合同时间</div>
                            <div>${roomInfo.contractDuration || '-'}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">对接人</div>
                            <div>${roomInfo.lesseeName}</div>
                        </div>
                        <div class="item right">
                            <div class="item-label">手机号</div>
                            <div>${roomInfo.lesseePhone}</div>
                        </div>
                    </div>
                `,
                });
            },
            getWater(tiem,item2) {
                let times = tiem||[];
                times = times.map(item => {
                    return {
                        name:'水表',
                        value:item
                    }
                })
                let item3 = item2 || [];
                item3 = item3.map(item => {
                    return {
                        name:'电表',
                        value:item
                    }
                })
                let params = times.concat(item3);
                console.log(params);
                this.$vc.emit("getHydropower", "number", {title: "水表/电表", tiem:params});
            },
            _queryRoomMethodDown(e) {
                if (e.keyCode == 13) {
                    this.queryRoom();
                }
            },
            /**
             * 修改选中的楼栋
             * @param {Object} e
             * */
            changeBuilding(e) {
                if (e.target.selectedIndex > 0) {
                    const building = this.buildings[e.target.selectedIndex - 1];
                    let floorSpace = [];
                    for (
                        let i = -(building.undergroundFloor || 0);
                        i <= building.aboveGroundFloor;
                        i++
                    ) {
                        if (i !== 0) {
                            floorSpace.push(i);
                        }
                    }
                    this.floorSpace = floorSpace;
                    return;
                }
                this.floorSpace = [];
            },
            // 获取楼栋列表
            getBuildings() {
                this.$fly
                    .post(queryBuildingUrl, {
                        id: "",
                        code: "",
                        regionCode: this.$vc.getCurrentRegion().code,
                        name: "",
                    })
                    .then((res) => {
                        this.buildings = res.data.datas;
                    });
            },
            getBinderName(binderInfo) {
                if (binderInfo == null) {
                    return "-";
                }
                return binderInfo.isEnterprise == 1
                    ? binderInfo.enterpriseName
                    : binderInfo.name + ",  " + binderInfo.link;
            },
            listRoom: function () {
                return this.$fly
                    .post(queryRoomRentUrl, {
                        regionId: this.$vc.getCurrentRegion().communityId,
                        ...this.queryRooms,
                    })
                    .then((res) => {
                        let listRoomData = [];

                        res.data.datas.forEach((building) => {
                            building.floors.forEach((floor) => {
                                floor.rooms.forEach((room) => {
                                    listRoomData.push({
                                        floor: floor.floor,
                                        buildingId: building.buildingId,
                                        buildingName: building.buildingName,
                                        ...room,
                                    });
                                });
                            });
                        });
                        listRoomData.sort((preVal, val) => {
                            const preTime = preVal.createTime
                                    .replace(/-/g, "")
                                    .replace(/:/g, ""),
                                currentTime = val.createTime.replace(/-/g, "").replace(/:/g, "");
                            return currentTime - preTime;
                        });
                        this.listRoomData = listRoomData;
                        return listRoomData;
                    })
                    .catch(() => []);
            },
            async queryRoom(pageNo = 1, pageSize = 10) {

                this.$fly.post(queryRoomRentUrls, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    ...this.queryRooms,
                    pageNo,
                    pageSize
                }).then(res => {
                    if(res.code != 0){
                        return
                    }
                    this.roomList = res.data.datas;
                    if (pageNo === 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize,
                        });
                    }
                })
            },
            getDivision(room) {
                this.$router.push({
                    name: 'roomPrivate',
                    params: room
                })
            },
        },
    };
</script>
<style lang="stylus" scoped>
    .input-group {
        width: 144px;
    }

    .cs-btn {
        width: 50px;

        &.btn-white {
            border-color: #1ab394;
            color: #1ab394;
        }
    }

    .result-panel table.table td, .result-panel table.table th {
        vertical-align: middle;

        p {
            margin: 0;

            &:not(:last-of-type) {
                margin-bottom: 15px;
            }
        }
    }

    .btn-active {
        color: #1dafff;
        text-decoration: underline;
        cursor: pointer;
    }
</style>
